// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arquitectonico-index-js": () => import("./../../../src/pages/arquitectonico/index.js" /* webpackChunkName: "component---src-pages-arquitectonico-index-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-escenico-index-js": () => import("./../../../src/pages/escenico/index.js" /* webpackChunkName: "component---src-pages-escenico-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-osram-aeropuertos-js": () => import("./../../../src/pages/osram/aeropuertos.js" /* webpackChunkName: "component---src-pages-osram-aeropuertos-js" */),
  "component---src-pages-osram-cine-js": () => import("./../../../src/pages/osram/cine.js" /* webpackChunkName: "component---src-pages-osram-cine-js" */),
  "component---src-pages-osram-entretenimiento-js": () => import("./../../../src/pages/osram/entretenimiento.js" /* webpackChunkName: "component---src-pages-osram-entretenimiento-js" */),
  "component---src-pages-osram-index-js": () => import("./../../../src/pages/osram/index.js" /* webpackChunkName: "component---src-pages-osram-index-js" */),
  "component---src-pages-osram-medicina-js": () => import("./../../../src/pages/osram/medicina.js" /* webpackChunkName: "component---src-pages-osram-medicina-js" */),
  "component---src-pages-osram-proyeccion-js": () => import("./../../../src/pages/osram/proyeccion.js" /* webpackChunkName: "component---src-pages-osram-proyeccion-js" */),
  "component---src-pages-osram-purificacion-js": () => import("./../../../src/pages/osram/purificacion.js" /* webpackChunkName: "component---src-pages-osram-purificacion-js" */),
  "component---src-pages-politicas-de-garantia-js": () => import("./../../../src/pages/politicas-de-garantia.js" /* webpackChunkName: "component---src-pages-politicas-de-garantia-js" */),
  "component---src-pages-producto-js": () => import("./../../../src/pages/producto.js" /* webpackChunkName: "component---src-pages-producto-js" */),
  "component---src-pages-productos-arquitectural-js": () => import("./../../../src/pages/productos/arquitectural.js" /* webpackChunkName: "component---src-pages-productos-arquitectural-js" */),
  "component---src-pages-productos-escenica-js": () => import("./../../../src/pages/productos/escenica.js" /* webpackChunkName: "component---src-pages-productos-escenica-js" */),
  "component---src-pages-productos-index-js": () => import("./../../../src/pages/productos/index.js" /* webpackChunkName: "component---src-pages-productos-index-js" */),
  "component---src-pages-proyecto-js": () => import("./../../../src/pages/proyecto.js" /* webpackChunkName: "component---src-pages-proyecto-js" */),
  "component---src-pages-proyectos-js": () => import("./../../../src/pages/proyectos.js" /* webpackChunkName: "component---src-pages-proyectos-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-pages-soluciones-index-js": () => import("./../../../src/pages/soluciones/index.js" /* webpackChunkName: "component---src-pages-soluciones-index-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-solution-js": () => import("./../../../src/templates/solution.js" /* webpackChunkName: "component---src-templates-solution-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

